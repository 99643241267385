import React, { useEffect } from 'react';
import '../styles/HeroSection.css'; 
// import hero from '../assets/hero.mp4';

const HeroSection = () => {
  useEffect(() => {
    const heroTextElement = document.querySelector('.hero-text');
    heroTextElement.classList.remove('slide-in-right');
    void heroTextElement.offsetWidth;
    heroTextElement.classList.add('slide-in-right');
  }, []); 

  return (
    <div className="hero-container" id="home">
      {/* Video Background */}
      <video className="hero-video" autoPlay loop muted playsInline>
        <source src={"https://firebasestorage.googleapis.com/v0/b/inspirove-13c9e.appspot.com/o/hero_optimized.mp4?alt=media&token=4bf8f605-3567-4a24-814e-1872e9a614f1"} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* Overlay for background effect */}
      <div className="hero-overlay"></div>
      <div className="hero-text">
        <h1>
          Transforming your ideas into reality with expert web, app, AI/ML development, and creative design services tailored for students and businesses.
        </h1>
      </div>
    </div>
  );
};

export default HeroSection;
