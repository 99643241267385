import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyADJHAV99okA85WFWySAsFDaCvjNYxFGdg",
  authDomain: "inspirove-13c9e.firebaseapp.com",
  projectId: "inspirove-13c9e",
  storageBucket: "inspirove-13c9e.appspot.com",
  messagingSenderId: "431890365071",
  appId: "1:431890365071:web:b1ba367a659402291e0efa",
  measurementId: "G-204VGZ6D5B"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
