import React from 'react';
import { FaInstagram, FaPhone, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-scroll';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      {/* Links section */}
      <div className="footer-links">
        <h4>Links</h4>
        <ul className="footer-nav">
          <li><Link to="about" smooth={true} duration={500}>About Us</Link></li>
          <li><Link to="services" smooth={true} duration={500}>Services</Link></li>
          <li><Link to="working" smooth={true} duration={500}>Working Process</Link></li>
          <li><Link to="techstack" smooth={true} duration={500}>Tech Stack</Link></li>
        </ul>
      </div>

      {/* Center contact information */}
      <div className="footer-contact">
        <div className="circleWrap">
          <svg xmlns="http://www.w3.org/2000/svg"
               xmlnsXhtml="http://www.w3.org/1999/xhtml"
               xmlnsXlink="http://www.w3.org/1999/xlink"
               version="1.1"
               width="10cm" height="10cm"
               viewBox="0 0 1000 1000"
               preserveAspectRatio="xMinYMin">
            <defs>
              <path id="textPath" d="M 250 500 A 250,250 0 1 1 250 500.0001"/>
            </defs>
            <text className="circle-text" x="0" y="0" textAnchor="middle">
              <textPath xlinkHref="#textPath" startOffset="50%">wanna work together?</textPath>
            </text>
          </svg>
          <h1 className="hello">contact us</h1>
        </div>
       
        <p >
          Interested in partnering with Inspirove? Let's work together to bring your ideas to life. Reach out to us today!
        </p>

      </div>

      {/* Follow Us Section */}
      <div className="footer-follow">
        <h4>Follow Us</h4>
        <div className="footer-social">
          <a href="https://www.instagram.com/inspirove_is?igsh=d3IwdDNnNHNvaGw=" target="_blank" rel="noopener noreferrer" className="social-icon instagram">
            <FaInstagram />
          </a>
          <a href="tel:7904117676" className="social-icon phone">
            <FaPhone />
          </a>
          <a href="https://wa.me/7904117676" className="social-icon whatsapp">
            <FaWhatsapp />
          </a>
          <a href="mailto:inspirovetech@gmail.com" className="social-icon email">
            <FaEnvelope />
          </a>
        </div>
        {/* Phone numbers and email */}
        <div className="footer-contact-info">
          <p className='e'>Email: inspirovetech@gmail.com</p>
          <p className='num'>Phone: 7904117676 || 6369400342</p>
        </div>
      </div>

      <div className="footer-copyright">
        <p>© 2024 Inspirove. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
