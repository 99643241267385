import React from 'react';
import './App.css';
import Header from './components/Header'; 
import HeroSection from './components/HeroSection';  
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import ProcessPage from './components/ProcessPage';
import TechStack from './components/TechStack';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />  
      <HeroSection />  
      <AboutUs />
      <Services/>
      <ProcessPage/>
      <TechStack/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
