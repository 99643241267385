import React, { useState, useEffect, useRef } from 'react';
import '../styles/Header.css';  
import logo from '../assets/inspriove.png';
import { IoIosArrowForward } from 'react-icons/io'; 

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarRef = useRef(null); 
  const hamburgerRef = useRef(null); // New ref for the hamburger

  const toggleMenu = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen); 
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if clicking outside of both sidebar and hamburger
      if (
        menuOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setMenuOpen(false); 
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <header className="header-container">
      <div className="header-logo">
      <a href="#home"><img src={logo} alt="Inspirove Logo" /></a>
      </div>
      <nav ref={sidebarRef} className={`header-nav ${menuOpen ? 'open' : ''}`}>
        <ul className="header-nav-links" >
          <li className='sidebarmbl'><a href="#home" className="header-nav-link" onClick={() => setMenuOpen(false)}>Home</a></li>
          <li className='sidebarmbl'><a href="#about" className="header-nav-link" onClick={() => setMenuOpen(false)}>About Us</a></li>
          <li className='sidebarmbl'><a href="#services" className="header-nav-link" onClick={() => setMenuOpen(false)}>Services</a></li>
          <li className='sidebarmbl'><a href="#working" className="header-nav-link" onClick={() => setMenuOpen(false)}>Working Process</a></li>
          <li className='sidebarmbl'><a href="#techstack" className="header-nav-link" onClick={() => setMenuOpen(false)}>Tech Stack</a></li>
        </ul>
        <div className="header-contact-btn">
          <a href="#contact" className="header-contact-link" onClick={() => setMenuOpen(false)}>
            Contact Us <b><IoIosArrowForward /></b>
          </a>
        </div>
      </nav>
      <div ref={hamburgerRef} className={`hamburger ${menuOpen ? 'is-active' : ''}`} onClick={toggleMenu}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
    </header>
  );
};

export default Header;
