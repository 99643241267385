import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/ProcessPage.css';
import { FaArrowRight, FaArrowDown, FaArrowLeft } from 'react-icons/fa';
import { FaFileAlt, FaPencilRuler, FaCode, FaTruck } from 'react-icons/fa';

const ProcessPage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="process-container" id="working">
      <h1 className="process-title">Working Process</h1>
      <div className="desktop-layout">
        <div className="step-card" data-aos="fade-up">
          <h2>Step 1</h2>
          <p className="subtitle"><FaFileAlt className="step-icon" /> <b>Documentation</b></p>
          <p>We discuss your needs and prepare a detailed plan with timeline and cost estimates.</p>
        </div>
        <FaArrowRight className="arrow-icon horizontal-arrow arrow-animation-right" />
        <div className="step-card" data-aos="fade-up">
          <h2>Step 2</h2>
          <p className="subtitle"><FaPencilRuler className="step-icon" /> <b>Design</b></p>
          <p>We create a custom design based on your preferences.</p>
        </div>
        <FaArrowDown className="arrow-icon vertical-arrow arrow-animation-down" />
        <div className="step-card" data-aos="fade-up">
          <h2>Step 4</h2>
          <p className="subtitle"><FaTruck className="step-icon" /> <b>Delivery</b></p>
          <p>We deliver the final product and assist with hosting, ensuring a smooth launch.</p>
        </div>
        <FaArrowLeft className="arrow-icon horizontal-arrow arrow-animation-left" />
        <div className="step-card" data-aos="fade-up">
          <h2>Step 3</h2>
          <p className="subtitle"><FaCode className="step-icon" /> <b>Development</b></p>
          <p>Our team brings the design to life with efficient coding.</p>
        </div>
      </div>

      <div className="mobile-layout">
        <div className="step-card" data-aos="fade-up">
          <h2>Step 1</h2>
          <p className="subtitle"><FaFileAlt className="step-icon" /> <b>Documentation</b></p>
          <p>We discuss your needs and prepare a detailed plan with timeline and cost estimates.</p>
        </div>
        <FaArrowDown className="arrow-icon mobile-arrow arrow-animation-down" />
        <div className="step-card" data-aos="fade-up">
          <h2>Step 2</h2>
          <p className="subtitle"><FaPencilRuler className="step-icon" /> <b>Design</b></p>
          <p>We create a custom design based on your preferences.</p>
        </div>
        <FaArrowDown className="arrow-icon mobile-arrow arrow-animation-down" />
        <div className="step-card" data-aos="fade-up">
          <h2>Step 3</h2>
          <p className="subtitle"><FaCode className="step-icon" /> <b>Development</b></p>
          <p>Our team brings the design to life with efficient coding.</p>
        </div>
        <FaArrowDown className="arrow-icon mobile-arrow arrow-animation-down" />
        <div className="step-card" data-aos="fade-up">
          <h2>Step 4</h2>
          <p className="subtitle"><FaTruck className="step-icon" /> <b>Delivery</b></p>
          <p>We deliver the final product and assist with hosting, ensuring a smooth launch.</p>
        </div>
      </div>
    </section>
  );
};

export default ProcessPage;
