import React from 'react';
import '../styles/Services.css';
import { FaLaptopCode, FaMobileAlt, FaBrain, FaUserTie, FaPenFancy, FaPalette } from 'react-icons/fa';
import service1 from '../assets/web.jpeg';
import service2 from '../assets/app.jpg';
import service3 from '../assets/Ai:Ml.avif';
import service4 from '../assets/portofilo.png';
import service5 from '../assets/poster.png';
import service6 from '../assets/logo_design.png';

const Services = () => {
  const servicesData = [
    {
      icon: <FaLaptopCode />,
      title: 'Web Development',
      image: service1,
      description: 'We build high-quality websites tailored to your needs.',
      colorClass: 'card-front__tp--city',
    },
    {
      icon: <FaMobileAlt />,
      title: 'App Development',
      image: service2,
      description: 'Innovative mobile apps for iOS and Android.',
      colorClass: 'card-front__tp--ski',
    },
    {
      icon: <FaBrain />,
      title: 'AI & ML Projects',
      image: service3,
      description: 'Advanced AI & ML solutions for complex problems.',
      colorClass: 'card-front__tp--beach',
    },
    {
      icon: <FaUserTie />,
      title: 'Portfolios',
      image: service4,
      description: 'Professional portfolios to showcase your work.',
      colorClass: 'card-front__tp--camping',
    },
    {
      icon: <FaPenFancy />,
      title: 'Poster Making',
      image: service5,
      description: 'Creative and eye-catching poster designs.',
      colorClass: 'card-front__tp--poster',
    },
    {
      icon: <FaPalette />,
      title: 'Logo Design',
      image: service6,
      description: 'Unique logo designs to represent your brand.',
      colorClass: 'card-front__tp--logo',
    },
    
  ];

  return (
    <section className="services-container" id="services">
      <h1 className="services-title">Our Services</h1>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div className="card-section" key={index}>
            <div className="card">
              <div className="flip-card">
                <div className="flip-card__container">
                  {/* Card Front */}
                  <div className="card-front">
                    <div className={`card-front__tp ${service.colorClass}`}>
                      {service.icon}
                      <h2 className="card-front__heading">{service.title}</h2>
                    </div>
                    <div className="card-front__bt">
                      <p className="card-front__text-view">View Details</p>
                    </div>
                  </div>
                  {/* Card Back */}
                  <div className="card-back">
                    <img src={service.image} alt={service.title} className="card-back__image" />
                    <p className="card-back__description">{service.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
