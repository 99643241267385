import React from 'react';
import '../styles/AboutUs.css'; 
import aboutImage from '../assets/AboutUs.png'; 
const AboutUs = () => {
  return (
    <section className="about-container" id="about">
      <div className="about-image">
        <img src={aboutImage} alt="About Us Visual" />
      </div>
      <div className="about-text">
        <h1>About Us</h1>
        <p>
          At Inspirove, we are passionate freelancers with a diverse skill set in web development, mobile app creation, AI/ML solutions, and college project assistance. We also specialize in eye-catching poster design, logo creation, and professional student portfolios. 
          Our goal is to bring your vision to life through innovative, reliable, and affordable services—whether you're a student looking to showcase your talent or a business aiming to make a lasting impact.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
