import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../firebase'; // Firebase configuration
import { collection, addDoc } from 'firebase/firestore'; // Firestore functions
import '../styles/Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({ name: '', phone: '', email: '', details: '' });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Ensure phone input contains only numbers
    if (name === 'phone' && !/^\d*$/.test(value)) {
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, phone, email, details } = formData;

    // Validate the phone number before submitting
    if (!validatePhoneNumber(phone)) {
      toast.error('Phone number must be exactly 10 digits');
      return;
    }

    setLoading(true);

    try {
      // Save data to Firebase
      await addDoc(collection(db, 'contacts'), {
        name,
        phone,
        email,
        details,
        timestamp: new Date(), // Optional: Save the timestamp of submission
      });

      // Sending email using EmailJS
      const templateParams = {
        to_name: 'Inspirove Team',
        from_name: name,
        from_email: email, // Sender's email
        phone, // Phone number
        message: details, // Message body
      };

      await emailjs.send(
        'service_lkeqdf6', // Your EmailJS service ID
        'template_fo3c0vd', // Your EmailJS template ID
        templateParams,
        's6NJ4wUtaxuBrYoyo' // Your public user ID (public key)
      );

      toast.success('Message sent and data saved successfully!');
      setFormData({ name: '', phone: '', email: '', details: '' }); // Clear form after submission

    } catch (error) {
      console.log('Error:', error);
      toast.error('Failed to send message or save data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-container" id='contact'>
      <form className="contact-form" onSubmit={handleSubmit}>
        <h2>Contact Us</h2>
        <div className="input-container">
          <input 
            type="text" 
            name="name" 
            value={formData.name} 
            onChange={handleInputChange} 
            placeholder=" " 
            required 
          />
          <label className="floating-label">Name</label>
        </div>

        <div className="input-container">
          <input 
            type="text" 
            name="phone" 
            value={formData.phone} 
            onChange={handleInputChange} 
            maxLength="10"
            placeholder=" "
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            required 
          />
          <label className="floating-label">Phone Number</label>
        </div>

        <div className="input-container">
          <input 
            type="email" 
            name="email" 
            value={formData.email} 
            onChange={handleInputChange} 
            placeholder=" " 
            required 
          />
          <label className="floating-label">Email</label>
        </div>

        <div className="input-container">
          <textarea 
            name="details" 
            value={formData.details} 
            onChange={handleInputChange} 
            placeholder=" " 
            required 
          />
          <label className="floating-label">Details</label>
        </div>

        <button type="submit" disabled={loading}>
          {loading ? 'Sending...' : 'Send Message'}
        </button>
      </form>

      <div className="contact-image">
        <img src={require('../assets/Contact-us.png')} alt="Contact Us" />
      </div>

      {/* Toast Container to show notifications */}
      <ToastContainer />
    </div>
  );
};

export default Contact;
