import React from 'react';
import '../styles/TechStack.css';
import { FaHtml5, FaCss3Alt, FaJs, FaReact, FaAngular, FaNodeJs, FaPython, FaJava, FaPhp } from 'react-icons/fa';
import { SiVite, SiTailwindcss, SiMysql, SiMongodb, SiFirebase, SiTensorflow, SiPytorch, SiOpencv, SiFlutter, SiPandas, SiNumpy } from 'react-icons/si';

const TechStack = () => {
  return (
    <section className="techstack-container" id='techstack'>
      <h1 className="techstack-title">Our Tech Stack</h1>
      
      <div className="techstack-grid">
        <div className="row">
          {/* Frontend Card */}
          <div className="card card-circle">
            <h5 className="card-title">Frontend</h5>
            <ul className="tech-list">
              <li><FaHtml5 /> HTML</li>
              <li><FaCss3Alt /> CSS</li>
              <li><FaJs /> JavaScript</li>
              <li><FaReact /> React JS</li>
              <li><FaAngular /> Angular JS</li>
              <li><SiVite /> Vite</li>
              <li><SiTailwindcss /> Tailwind</li>
            </ul>
          </div>

          {/* Backend Card */}
          <div className="card card-circle">
            <h5 className="card-title">Backend</h5>
            <ul className="tech-list">
              <li><FaPython /> Python</li>
              <li><FaJava /> Java</li>
              <li><FaNodeJs /> Node JS</li>
              <li><FaPhp /> PHP</li>
            </ul>
          </div>

          {/* Database Card */}
          <div className="card card-circle">
            <h5 className="card-title">Database</h5>
            <ul className="tech-list">
              <li><SiFirebase /> Firebase</li>
              <li><SiMongodb /> MongoDB</li>
              <li><SiMysql /> MySQL</li>
            </ul>
          </div>

          {/* AI & ML Card */}
          <div className="card card-circle">
            <h5 className="card-title">AI & ML</h5>
            <ul className="tech-list">
              <li><SiTensorflow /> TensorFlow</li>
              <li><SiPytorch /> PyTorch</li>
              <li><SiOpencv /> OpenCV</li>
              <li><SiPandas /> Pandas</li>
              <li><SiNumpy /> NumPy</li>
            </ul>
          </div>

          {/* App Development Card */}
          <div className="card card-circle">
            <h5 className="card-title">App Development</h5>
            <ul className="tech-list">
              <li><SiFlutter /> Flutter</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechStack;
